import styled from 'styled-components'
import Proptypes from 'prop-types'
import TextareaField from './textarea-field'
import MaxLengthText from './max-length-text'

export const InputFieldStyled = styled.div`
  position: ${({ maxLength }) => (maxLength ? 'relative' : 'static')};
  label {
    display: block;
    font: var(--regular);
    margin-block-end: 4px;
  }
  input, textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 12px 8px;
    border: 1px solid var(--neutral70);
    color: var(--baseSecondary);
    background: var(--basePrimary);
    font: var(--regular);
    border-radius: 4px;
    outline: none;
    &:active, &:focus {
      border: ${({ maxAchived, maxLength }) => ((maxLength && maxAchived) ? '1px solid var(--error)' : '1px solid var(--primary70)')};
    }
  }
  textarea {
    resize: vertical;
    min-height: 124px;
  }
`

function InputField(props) {
  const {
    className, label, onChange, type, value, name, dataCy, required, placeholder, textArea, maxLength,
  } = props
  return (
    <InputFieldStyled className="input-container" maxLength={maxLength} maxAchived={value?.length > maxLength}>
      <label htmlFor={label}>{label}</label>
      {textArea ? (
        <TextareaField {...props} />
      ) : (
        <>
          <input
            className={className}
            id={label}
            type={type}
            data-cy={dataCy}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            required={required}
            name={name}
          />
          {maxLength ? (
            <MaxLengthText maxLength={maxLength} value={value} />
          ) : null}
        </>
      )}
    </InputFieldStyled>
  )
}

InputField.defaultProps = {
  required: false,
  textArea: false,
  type: 'text',
  className: '',
  maxLength: 0,
  value: '',
  dataCy: 'input-field',
}

InputField.propTypes = {
  label: Proptypes.string.isRequired,
  onChange: Proptypes.func.isRequired,
  name: Proptypes.string.isRequired,
  dataCy: Proptypes.string,
  placeholder: Proptypes.string.isRequired,
  value: Proptypes.string,
  type: Proptypes.string,
  textArea: Proptypes.bool,
  required: Proptypes.bool,
  className: Proptypes.string,
  maxLength: Proptypes.number,
}

export default InputField
