import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import PhotosGrid from 'components/login/photos-grid'
import Wrapper from 'common/wrapper'
import { Typography } from 'common/typography'
import Divider from 'common/divider'
import GoogleAuth from './google-auth'
import GithubAuth from './github-auth'
// import Annnoncement from 'components/common/navigation/announcement'

const AuthLayoutStyled = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  .auth-content {
    width: 100%;
  }
  .auth-container {
    display: grid;
    grid-row-gap: 16px;
    justify-items: center;
    grid-template-rows: repeat(6, min-content);
    padding-top: 24px;
  }
  .auth-link {
    font-size: 14px;
    line-height: 24px;
  }
  .auth-link-top {
    display: none;
  }
  .link {
    font-weight: 600;
    text-decoration: underline;
    color: var(--secondary);
    margin-left: 8px;
    transition: 0.3s ease-in-out;
    letter-spacing: 0.0125em;
    &:hover {
      color: var(--secondaryHover);
    }
    &:active {
      color: var(--secondaryActive);
    }
  }
  h1 {
    text-align: center;
  }
  .forms-container {
    width: 100%;
  }
  .auth-logo {
    max-width: 48px;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    h1 {
      text-align: left;
    }
    .auth-logo {
      max-width: none;
    }
    .auth-section {
      padding: 40px;
    }
    .auth-container {
      align-content: center;
      justify-items: start;
      min-block-size: calc(100vh - 104px);
      padding: 0;
      grid-row-gap: 24px;
    }
    .auth-link {
      display: none;
    }
    .auth-link-top {
      display: block;
      font: normal 16px/24px var(--primaryFont);
      text-align: right;
    }
  }
`

function AuthLayout({
  children, title, googleCta, query, link, linkCta, googleSucces, githubCta, authDisabled,
}) {
  return (
    <AuthLayoutStyled>
      {/* <Annnoncement /> */}
      <PhotosGrid />
      <div className="auth-section">
        {!authDisabled && (
          <p className="auth-link-top">
            {query}
            <Link href={link} className="link">
              {linkCta}
            </Link>
          </p>
        )}
        <Wrapper maxWidth="350">
          <div className="auth-container">
            <Link href="/" className="auth-logo">
              <Image width="80" height="80" src="/icons/icon-152x152.png" alt="logo" />
            </Link>
            {!authDisabled && (
              <Typography as="h1" small="regularBold" large="headline5">
                {title}
              </Typography>
            )}
            <div className="forms-container">
              {!authDisabled && (
                  <>
                    <GoogleAuth googleCta={googleCta} googleSucces={googleSucces} />
                    <Divider marginVertical={8} stroke={1} opacity="1" width="100%" color="transparent" />
                    <GithubAuth githubCta={githubCta} />
                    {children && (
                      <Divider marginVertical={16} stroke={1} opacity="1" width="100%" color="var(--neutral80)" />
                    )}
                  </>
              )}
              <div className="auth-content">
                {children}
              </div>
            </div>
            {!authDisabled && (
              <p className="auth-link">
                {query}
                <Link href={link} className="link">
                  {linkCta}
                </Link>
              </p>
            )}
          </div>
        </Wrapper>
      </div>
    </AuthLayoutStyled>
  )
}

AuthLayout.defaultProps = {
  authDisabled: false,
  googleSucces: () => {},
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  googleCta: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkCta: PropTypes.string.isRequired,
  authDisabled: PropTypes.bool,
  googleSucces: PropTypes.func,
  githubCta: PropTypes.string.isRequired,
}

export default AuthLayout
