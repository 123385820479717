
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const useInputValue = (initialValue, handleChange) => {
  const [value, setValue] = useState(initialValue)
  const [disable, setDisable] = useState(true)
  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue)
    }
  }, [initialValue])
  const onChange = (event) => {
    const newValue = event.target.value
    setValue(newValue)
    if (!newValue) {
      setDisable(true)
    }
    setDisable(false)
    if (handleChange) handleChange(event)
  }
  let active = false
  if (value) {
    active = true
  }
  return {
    value, onChange, active, setValue, disable, setDisable,
  }
}

export const inputProps = PropTypes.shape({
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  disable: PropTypes.bool.isRequired,
  setDisable: PropTypes.func.isRequired,
})

export default useInputValue
