/* eslint-disable no-console */
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FcGoogle } from 'react-icons/fc'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { sendToDataLayer } from 'utils/push-to-datalayer'

const GoogleAuthStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-shadow: none !important;
    background-color: var(--white) !important;
    color: var(--neutral100) !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    font-family: var(--primaryFont) !important;
    height: 48px !important;
    cursor: pointer;
    &:hover {
      background: #ececec !important;
      div {
        background: #ececec !important;
      }
    }
    &:active {
      background: #dddddd !important;
      div {
        background: #dddddd !important;
      }
    }
    div {
      background-color: var(--white) !important;
    }
  }
`

function GoogleAuth({ googleCta }) {
  const { pathname } = useRouter()

  const googleAuth = () => {
    if (pathname === '/registro') {
      sendToDataLayer({
        event: 'sign_up',
        method: 'google',
      })
    }
    if (pathname === '/login') {
      sendToDataLayer({
        event: 'login',
        method: 'google',
      })
    }
    const nextUrl = window.location.search.startsWith('?next=')
      ? window.location.search.slice(6)
      : '/aprender'
    signIn('google', {
      callbackUrl: nextUrl,
    })
  }

  return (
    <GoogleAuthStyled>
      <button type="button" onClick={() => googleAuth()}>
        <FcGoogle size="20px" />
        <span>{googleCta}</span>
      </button>
    </GoogleAuthStyled>
  )
}

GoogleAuth.propTypes = {
  googleCta: PropTypes.string.isRequired,
}

export default GoogleAuth
