import MaxLengthText from './max-length-text'

function TextareaField({
  className, label, onChange, type, value, name, dataCy, required, placeholder, maxLength,
}) {
  const textAreaChange = (e) => {
    onChange(e)
  }

  return (
    <>
      <textarea
        id={label}
        type={type}
        data-cy={dataCy}
        onChange={textAreaChange}
        value={value}
        placeholder={placeholder}
        required={required}
        name={name}
        className={className}
        maxLength={maxLength || false}
      />
      {maxLength ? (
        <MaxLengthText maxLength={maxLength} value={value} />
      ) : null}
    </>
  )
}

export default TextareaField
