import { useRef, useEffect, memo } from 'react'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import Photos from './photos'

const PhotosGridStyled = styled.div`
  background-color: var(--primary);
  position: relative;
  overflow: hidden;
  .mobile {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, minmax(82px, 1fr));
  }
  .filter {
    position: relative;
    &::after{
      color: white;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(44, 85, 243);
      opacity: 0.65;
      transition: all 2s ease;
    }
  }
  .no-filter{
    &::after{
      opacity: 0;
    }
  }
  .desktop{
    display: none;
  }
  @media screen and (min-width: 768px) {
    .mobile {
      grid-template-rows: repeat(2, minmax(150px, 1fr));
    }
  }
  @media screen and (min-width: 1024px) {
    position: sticky;
    overflow: hidden;
    height: 100vh;
    top: 0px;
    .mobile {
      display: none;
    }
    .desktop {
      display: grid;
      height: 100vh;
      grid-template-columns: repeat(5, 10.3vw);
      grid-template-rows: repeat(5, 1fr);
      position: absolute;
      right: 0;
    }
  }
`

function PhotosGrid() {
  const gridMobile = useRef(null)
  const gridDesktop = useRef(null)

  useEffect(() => {
    const imgDesktop = gridDesktop.current.childNodes
    const imgMobile = gridMobile.current.childNodes

    const randomImg = (imgs, nImages, rowsNumers) => {
      const randomNumbers = []
      const rows = []
      while (randomNumbers.length < nImages) {
        const n = Math.floor(Math.random() * rowsNumers)
        if (randomNumbers.includes(n) === false) {
          randomNumbers.push(n)
          const row = imgs.splice(0, rowsNumers)
          rows.push(row)
        }
      }
      rows.forEach((row, idx) => {
        const randomPosition = randomNumbers[idx]
        const item = row[randomPosition]
        item.classList.add('no-filter')
        setTimeout(() => {
          item.classList.remove('no-filter')
        }, 2900)
      })
    }
    randomImg([...imgDesktop], 5, 5)
    randomImg([...imgMobile], 2, 4)
    setInterval(() => {
      randomImg([...imgDesktop], 5, 5)
      randomImg([...imgMobile], 2, 4)
    }, 3000)
  }, [])

  return (
    <PhotosGridStyled>
      <div className="mobile" ref={gridMobile}>
        {Photos.slice(0, 10).map(({ id, url }) => (
          <div className="filter" key={id}>
            <Image
              src={url}
              alt={id}
              className="grid-img"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
        ))}
      </div>
      <div className="desktop" ref={gridDesktop}>
        {Photos.map(({ id, url }) => (
          <div className="filter" key={id}>
            <Image
              src={url}
              alt={id}
              className="grid-img"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
        ))}
      </div>
    </PhotosGridStyled>
  )
}

const PhotosGridMemo = memo(PhotosGrid)

export default PhotosGridMemo
