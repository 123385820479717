// import { useState, useEffect } from 'react'
import styled from 'styled-components'

const MaxLengthTextStyled = styled.span`
  background: var(--basePrimary);
  position: absolute;
  width: 50px;
  height: 16px;
  right: 8px;
  bottom: 8px;
  z-index: 1;
  display: block;
  font: var(--smallRegular);
  color: ${({ maxAchived }) => (maxAchived ? 'var(--error)' : 'var(--neutral60)')};
  text-align: right;
`

function MaxLengthText({ maxLength, value }) {
  // const initialValue = (maxLength - value?.length) || maxLength
  // const [count, setCount] = useState(initialValue)

  // useEffect(() => {
  //   if (maxLength && typeof value === 'string') {
  //     setCount(maxLength - value?.length)
  //   }
  // }, [value])

  return (
    <MaxLengthTextStyled maxAchived={value?.length > maxLength}>
      {value?.length || 0}
      /
      {maxLength}
    </MaxLengthTextStyled>
  )
}

export default MaxLengthText
