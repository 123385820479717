import felipe_merchan from 'public/images/testimonies/felipe_merchan.png'
import codeka_code from 'public/images/testimonies/codeka_code.png'
import daniel_suarez from 'public/images/testimonies/daniel_suarez.png'
import juan_n from 'public/images/testimonies/juan_nahuat.png'
import diana_rodrigez from 'public/images/testimonies/diana_rodriguez.png'
import katherin_suarez from 'public/images/testimonies/katherin_suarez_2.png'
import juan_pablo_solana from 'public/images/testimonies/juan_pablo_solana.png'
import dafne_villarroel from 'public/images/testimonies/dafne_villarroel.png'
import luis_abdel from 'public/images/testimonies/luis_abdel_flores.png'
import linda_reyes from 'public/images/testimonies/linda_reyes.png'
import lenrmatterano from 'public/images/testimonies/lenrmatterano.png'
import manuel_saavedra from 'public/images/testimonies/manuel_saavedra.png'
import ivan_bustos from 'public/images/testimonies/ivan_bustos.png'
import rosa_mora from 'public/images/testimonies/rosa_mora.png'
import rosa_trejo from 'public/images/testimonies/rosa_trejo.png'
import juan_carlos_valerio from 'public/images/testimonies/juan_carlos_valerio.png'
import juan_esteban_londono from 'public/images/testimonies/juan_esteban_londono.png'
import franciree_arellan from 'public/images/testimonies/franciree_arellan.png'
import marisol_carrillo from 'public/images/testimonies/marisol_carrillo.png'
import paola_jazmin_salvador from 'public/images/testimonies/paola_jazmin_salvador.png'
import jose_lezama from 'public/images/patreon/list1/jose-lezama.jpg'
import luciana_virginia from 'public/images/testimonies/luciana_virginia.png'
import raul_santa_cruz from 'public/images/testimonies/raul_santa_cruz.png'
import andres_cuber from 'public/images/testimonies/andres_cuber.png'
import a_eliz from 'public/images/testimonies/a-elizabeth.png'

const Photos = [
  {
    id: 'LUCIANA_VIRGINIA',
    url: luciana_virginia,
  }, {
    id: 'A_ELIZ',
    url: a_eliz,
  }, {
    id: 'DANIEL_SUAREZ',
    url: daniel_suarez,
  }, {
    id: 'CODEKA_CODE',
    url: codeka_code,
  }, {
    id: 'IVAN_BUSTOS',
    url: ivan_bustos,
  }, {
    id: 'KATHERIN_SUAREZ',
    url: katherin_suarez,
  }, {
    id: 'JUAN_PABLO_SOLANA',
    url: juan_pablo_solana,
  },  {
    id: 'DAFNE_VILLARROEL',
    url: dafne_villarroel,
  }, {
    id: 'MANUEL_SAAVEDRA',
    url: manuel_saavedra,
  }, {
    id: 'FRANCIREE_ARELLAN',
    url: franciree_arellan,
  },

  {
    id: 'LUIS_ABDEL',
    url: luis_abdel,
  }, {
    id: 'LENRMATTERANO',
    url: lenrmatterano,
  }, {
    id: 'JUAN_N',
    url: juan_n,
  }, {
    id: 'DIANA_RODRIGEZ',
    url: diana_rodrigez,
  }, {
    id: 'JUAN_CARLOS_VALERIO',
    url: juan_carlos_valerio,
  }, {
    id: 'MARISOL_CARRILLO',
    url: marisol_carrillo,
  },  {
    id: 'ANDRES_CUBER',
    url: andres_cuber,
  }, {
    id: 'LINDA_REYES',
    url: linda_reyes,
  }, {
    id: 'RAUL_SANTA_CRUZ',
    url: raul_santa_cruz,
  }, {
    id: 'ROSA_MORA',
    url: rosa_mora,
  }, {
    id: 'JUAN_ESTEBAN_LONDOÑO',
    url: juan_esteban_londono,
  }, {
    id: 'PAOLA_JAZMIN_SALVADOR',
    url: paola_jazmin_salvador,
  }, {
    id: 'FELIPE_MERCHAN',
    url: felipe_merchan,
  }, {
    id: 'ROSA_TREJO',
    url: rosa_trejo,
  }, {
    id: 'JOSE_LEZAMA',
    url: jose_lezama,
  },
]

export default Photos
