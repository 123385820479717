import styled from 'styled-components'
import PropTypes from 'prop-types'
import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { FaGithub } from 'react-icons/fa'
import { Button } from 'common/button'
import { sendToDataLayer } from 'utils/push-to-datalayer'

const IconStyled = styled.div`
  svg {
    fill: var(--neutral100);
  }
  button {
    color: var(--neutral100);
    background: var(--white);
    border: 1px solid rgba(0,0,0, 0.1)!important;
    transition: .3s background ease-in;
    font-family: var(--primaryFont);
    &:hover {
      background: #ECECEC;
    }
    &:active {
      background: #DDDDDD;
    }
  }
  svg {
    margin-right: 18px;
  }
`

function GitHubButton({ githubCta }) {
  const { pathname } = useRouter()

  const githubAuth = () => {
    if (pathname === '/registro') {
      sendToDataLayer({
        event: 'sign_up',
        method: 'github',
      })
    }
    if (pathname === '/login') {
      sendToDataLayer({
        event: 'login',
        method: 'github',
      })
    }
    signIn('github')
  }

  return (
    <IconStyled>
      <Button onClick={() => githubAuth()} height="48" fullWidth fontWeight="500">
        <FaGithub size="20px" />
        {githubCta}
      </Button>
    </IconStyled>
  )
}

GitHubButton.defaultProps = {
  githubCta: 'Iniciar sesión con GitHub',
}

GitHubButton.propTypes = {
  githubCta: PropTypes.string,
}

export default GitHubButton
